.parent {
	position: relative;
}
.footer {
	position: absolute;
	left: 50%;
	top: 95%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
