.searchSegment {
	height: 9em !important;
	margin-top: 1% !important;
}

.searchField {
}

.searchInput {
	width: 50% !important;
	/* margin-left: 15% !important; */
	margin-right: 1% !important;
}

.button {
}

.checkboxes {
	margin-top: 2rem;
	display: flex;
	justify-content: space-evenly;
}
